/** @format */

import $ from "jquery";
import * as Sentry from "@sentry/browser";
import { getContext, onIOS, toTitleCase } from "./toolkit";
import { chargehiveContext } from "./requests/get";
import {
    chargehiveUpsellContext,
    createAccount,
    finalizeOrder,
    processOrder,
    processUpsell,
    reactivate,
} from "./requests/post";
import { EventEmitter } from "events";
import { LightboxComponent } from "./components/LightboxComponent";
import { address } from "./requests/save";

declare const ChargeHive;
declare const PCIBridge;

export class Chive extends EventEmitter {
    private pageContext: object;
    public initContext: object;
    public products: Product[] = [];
    public customerReference: string;
    public reference: string;
    public chargeId: string;
    public token: string;
    public cardInfo: object;
    public cardholderName: string;
    public nameGiven: boolean = false;
    public applePay: boolean = false;
    public applePayInline: boolean = false;
    public orderFid: string;
    public shipped: boolean = true;
    public currency: string;
    public inlineAddress: boolean = false;
    public freeTrial: boolean = false;
    public upsell: boolean = false;
    public options: object = {};

    constructor() {
        super();

        this.pageContext = getContext();
        this.currency = this.pageContext["currency"] || "GBP";
        let chContext = this.pageContext["chargehive"];

        if (!chContext) {
            return;
        }

        this.initContext = chContext;

        if (chContext["objective"] == "save") {
            this.customerReference = chContext["customer_reference"];

            PCIBridge.initialize({
                merchantHash: chContext["merchant_hash"],
                merchantPlacement: chContext["merchant_placement"],
                billingProfileId: chContext["customer_reference"],
            });
        }

        let walletOptions = {};

        if (chContext["lp_checkout"]) {
            walletOptions = {
                requireEmail: true,
                requireBillingPhone: true,
                requireShippingAddress: true,
                requireShippingPhone: true,
            };
        } else if ($("[name=inline_address]").length) {
            this.inlineAddress = true;
            walletOptions = {
                requireBillingAddress: true,
            };
        }

        if (this.pageContext["free_trial"]) {
            this.freeTrial = true;
        }

        ChargeHive.initialize({
            environment: "CHARGE_ENVIRONMENT_ECOMMERCE",
            country: this.getCountryCode(),
            currency: this.currency,
            projectId: chContext["project"],
            projectPlacement: chContext["token"],
            merchantHash: chContext["merchant_hash"],
            merchantPlacement: chContext["merchant_placement"],
            walletOptions: walletOptions,
        });

        this.setupListeners();
    }

    public async submit(): Promise<boolean> {
        let response;
        this.applePay = false;

        try {
            if (this.products[0].price == 0) {
                response = await ChargeHive.tokenize();
            } else {
                response = await ChargeHive.authorize();
            }
        } catch (e) {
            throw new ChiveError("Failed to authorize", e);
        }

        if (!response) {
            throw new ChiveError("Failed to authorize");
        }

        if (response["error"]) {
            throw new ChiveError(`Failed to authorize: ${response["error"]}`);
        }

        this.chargeId = response["chargeId"];
        return true;
    }

    public async applePaySubmit(quantity: number): Promise<void> {
        let tokenResponse;
        this.applePay = true;

        try {
            tokenResponse = await ChargeHive.tokenize();
        } catch (e) {
            throw new ChiveError("Failed to authorize", e);
        }

        if (tokenResponse["error"]) {
            throw new ChiveError(
                `Failed to authorize: ${tokenResponse["error"]}`
            );
        }

        this.cardInfo = this.stripCardInfo(tokenResponse);
        this.chargeId = tokenResponse["chargeId"];
        let customer = ChargeHive.charge.meta["customerInfo"];
        let petNames = [];

        for (let i = 0; i < quantity; i++) {
            petNames.push("");
        }

        let accountResponse = await createAccount(
            petNames,
            customer["fullName"],
            customer["email"],
            "",
            false,
            this.currency,
            false,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true
        ).catch(error => {
            if (error.response.status === 403) {
                LightboxComponent.getDisplay(
                    window.gettext(
                        "This email address is already in use. Please use a different email address or log in to your existing account."
                    ),
                    true
                );
            } else {
                LightboxComponent.getDisplay(
                    window.gettext(
                        "There was an error creating your account. Please try again later."
                    ),
                    true
                );
            }

            return;
        });

        this.customerReference = accountResponse["customer_fid"];

        let orderResponse = await processOrder(
            this.chargeId,
            this.getProductCodes(),
            this.reference,
            this.cardInfo,
            this.shipped
        );

        this.orderFid = orderResponse["fid"];

        ChargeHive.updateCharge({
            merchantReference: this.orderFid,
            billingProfileId: this.customerReference,
            references: {
                fortifi_order_fid: this.orderFid,
                set_as_default: this.customerReference,
            },
        });

        let authResponse;

        try {
            authResponse = await ChargeHive.authorize();
        } catch (e) {
            throw new ChiveError("Failed to authorize", e);
        }

        if (authResponse["error"]) {
            throw new ChiveError(
                `Failed to authorize: ${authResponse["error"]}`
            );
        }
    }

    public async tokenize(): Promise<object> {
        let response: any;

        try {
            response = await ChargeHive.tokenize();
        } catch (e) {
            throw new ChiveTokenizeError("Failed to tokenize", e);
        }

        return this.stripCardInfo(response);
    }

    public async setPersonalInfo(
        email: string,
        name?: string,
        setName: boolean = true
    ): Promise<void> {
        let customerInfo = {
            email: email,
        };

        if (name) {
            customerInfo["fullName"] = name;
            customerInfo["firstName"] = toTitleCase(name.split(" ")[0]);
            customerInfo["lastName"] = toTitleCase(name.split(" ")[1]);
            this.nameGiven = true;
        }

        await ChargeHive.setCustomerInfo(customerInfo);

        if (name && setName) {
            await this.setCardName(name);
        }
    }

    public async setCardName(name: string): Promise<void> {
        await ChargeHive.setNameOnCard(toTitleCase(name));
    }

    public async setBillingAddress(
        street: string,
        city: string,
        county: string,
        postal_code: string
    ): Promise<void> {
        await ChargeHive.setBillingAddress({
            address1: street,
            city: city,
            county: county,
            country: this.getCountryCode(),
            postal: postal_code,
        });
    }

    public async setupCharge(random_reference: boolean = false): Promise<void> {
        let vetFirst = this.pageContext["vet_first"];
        let chContext = await chargehiveContext(
            random_reference,
            Boolean(vetFirst)
        );

        this.products = chContext["products"];
        this.reference = chContext["reference"];
        this.customerReference = chContext["customer_reference"];
        this.options = chContext["options"];

        this.addOrderDetails();
        await this.prepareCharge();
    }

    public async setupUpsellCharge(
        productCode: string,
        quantity: number = 1
    ): Promise<void> {
        this.upsell = true;
        let chContext = await chargehiveUpsellContext(productCode, quantity);

        this.products = chContext["products"];
        this.reference = chContext["reference"];
        this.customerReference = chContext["customer_reference"];
        this.options = chContext["options"];

        this.addOrderDetails();
        await this.prepareCharge(false);

        this.setPaymentMethod("PLACEMENT_CAPABILITY_TOKEN", {
            token: this.token,
        });
    }

    public addOrderDetails(): void {
        for (let product of this.products) {
            ChargeHive.addOrderItem({
                name: product.name,
                unitPrice: product.price,
            });
        }
    }

    public async prepareCharge(setAsDefault: boolean = true): Promise<void> {
        let data = {
            amount: this.getTotalCost(),
            currency: this.currency,
            references: {
                order_fid: this.reference,
            },
        };

        if (this.customerReference) {
            data["billingProfileId"] = this.customerReference;
            data["references"]["customer_fid"] = this.customerReference;
            if (setAsDefault) {
                data["references"]["set_as_default"] = this.customerReference;
            }
        }

        let presetChargeId = this.initContext["charge_id"];

        if (presetChargeId) {
            delete data["references"]["order_fid"];
            await ChargeHive.prepareChargeWithId(presetChargeId, data);
        } else {
            await ChargeHive.prepareCharge(this.reference, data);
        }
    }

    public getTotalCost(): number {
        let totalCost = 0;

        for (let product of this.products) {
            totalCost += product.price;
        }

        return totalCost;
    }

    private stripCardInfo(data: object): object {
        let last4 = data["name"].slice(-4);
        let brand: string;
        let scheme = data["scheme"].toLowerCase();
        let expiry = data["expiry"];

        if (scheme.includes("visa")) {
            brand = "Visa";
        } else if (scheme.includes("master")) {
            brand = "Mastercard";
        } else if (scheme.includes("american")) {
            brand = "American Express";
        } else {
            brand = "Card";
        }

        let cardInfo = {
            details: `${brand} ending ${last4}`,
        };

        if (expiry) {
            cardInfo["expiry_month"] = expiry.split("/")[0];
            cardInfo["expiry_year"] = expiry.split("/")[1];
        }

        return cardInfo;
    }

    static addError(field: string): void {
        $(`[data-chargehive-container="${field}"]`).addClass("error");
    }

    static removeError(field: string): void {
        $(`[data-chargehive-container="${field}"]`).removeClass("error");
    }

    public setPaymentMethod(paymentMethod: string, data?: object): void {
        if (data) {
            ChargeHive.setPaymentMethod(paymentMethod, data);
        } else {
            ChargeHive.setPaymentMethod(paymentMethod);
        }
    }

    public getProductCodes(): string[] {
        let productCodes = [];

        for (let product of this.products) {
            productCodes.push(product.code);
        }

        return productCodes;
    }

    public async setBilling(values: object): Promise<void> {
        if (this.inlineAddress) {
            return;
        }

        let billingLine1 = $("[name=billing_line_1]").val() as string;
        let billingCity = $("[name=billing_city]").val() as string;
        let billingState = $("[name=billing_state]").val() as string;
        let billingPostcode = $("[name=billing_postal_code]").val() as string;

        let billingSameElement = $("[id=billing_same__true]");

        if (
            billingSameElement.length &&
            billingSameElement.prop("checked") == true
        ) {
            billingLine1 = values["line_1"];
            billingCity = values["city"];
            billingState = values["state"];
            billingPostcode = values["postal_code"];
        } else if (!values["billing_saved"]) {
            await address(
                "Billing",
                billingLine1,
                billingCity,
                billingState,
                billingPostcode,
                false,
                true
            );
            values["billing_saved"] = true;
        }

        await this.setBillingAddress(
            billingLine1,
            billingCity,
            billingState,
            billingPostcode
        );
    }

    private setupListeners(): void {
        ChargeHive.addEventListener("init", event => {
            this.emit("init", null, null);
        });

        ChargeHive.addEventListener("capability", async event => {
            let details = event.detail;

            if (!details["result"]) {
                return;
            }

            if (details["type"] == "PLACEMENT_CAPABILITY_APPLE_PAY") {
                $("[data-apple-pay]").css("display", "table");
            } else if (
                details["type"] == "PLACEMENT_CAPABILITY_GOOGLE_PAY" &&
                !onIOS()
            ) {
                $("[data-google-pay]").css("display", "table");
            }
        });

        ChargeHive.addEventListener("token", async event => {
            this.cardInfo = this.stripCardInfo(event.detail);
            this.token = event.detail["token"];

            if (this.freeTrial) {
                await processOrder(
                    "", // Clear chargeId to prevent immediate charge
                    this.getProductCodes(),
                    this.reference,
                    this.cardInfo,
                    this.shipped,
                    this.freeTrial,
                    this.cardholderName
                );

                this.emit("token", null, null);
            }
        });

        ChargeHive.addEventListener("success", async event => {
            this.cardholderName = this.scrapeName();
            this.chargeId = event.detail["chargeId"];

            if (this.applePayInline) {
                let billing = ChargeHive.charge.meta["billingAddress"];

                await address(
                    "Owner",
                    billing["address1"],
                    billing["city"],
                    billing["county"],
                    billing["postal"],
                    true
                );
            }

            if (this.initContext["objective"] == "reactivate") {
                let presetChargeId = this.initContext["charge_id"];
                await reactivate(
                    this.cardInfo,
                    Boolean(presetChargeId),
                    this.chargeId
                );
            } else if (this.applePay) {
                await finalizeOrder(this.chargeId, this.orderFid);

                let shipping = ChargeHive.charge.meta["shippingAddress"];
                let billing = ChargeHive.charge.meta["billingAddress"];

                await address(
                    "Owner",
                    shipping["address1"],
                    shipping["city"],
                    shipping["county"],
                    shipping["postal"],
                    true
                );

                if (
                    shipping["address1"] !== billing["address1"] &&
                    shipping["postal"] !== billing["postal"]
                ) {
                    await address(
                        "Billing",
                        billing["address1"],
                        billing["city"],
                        billing["county"],
                        billing["postal"],
                        false,
                        true
                    );
                }

                this.emit("success", null, null);
                return;
            } else {
                let nameParam = null;

                if (!this.nameGiven) {
                    nameParam = this.cardholderName;
                }

                if (this.upsell) {
                    await processUpsell(
                        this.getProductCodes(),
                        this.reference,
                        this.chargeId
                    );
                } else {
                    await processOrder(
                        this.chargeId,
                        this.getProductCodes(),
                        this.reference,
                        this.cardInfo,
                        this.shipped,
                        false,
                        nameParam
                    );
                }
            }

            this.emit("success", null, null);
        });

        ChargeHive.addEventListener("declined", async event => {
            let reason = event.detail["message"];
            Sentry.captureMessage("ChargeHive Declined", {
                extra: event.detail,
            });
            this.emit("declined", reason, null);
        });

        ChargeHive.addEventListener("error", async event => {
            try {
                Sentry.captureMessage("ChargeHive Error", {
                    extra: event,
                });
            } catch (e) {
                Sentry.captureMessage(`ChargeHive Error: ${e}`);
            }

            this.emit("error", null, null);
        });

        ChargeHive.addEventListener("cancel", async () => {
            this.emit("cancel", null, null);
        });

        ChargeHive.addEventListener("field-validation", event => {
            let field = event.detail["field"];
            Chive.removeError(field);
        });
    }

    private getCountryCode(): string {
        switch (this.currency) {
            case "USD":
                return "US";
            case "CAD":
                return "CA";
            default:
                return "GB";
        }
    }

    private scrapeName(): string {
        let metaName: string;
        let tokenName: string;

        try {
            metaName = ChargeHive._charge["meta"]["customerInfo"]["fullName"];
        } catch (e) {
            metaName = null;
        }

        if (!metaName) {
            try {
                tokenName = ChargeHive._tokenizationData.nameOnCard;
            } catch (e) {
                tokenName = null;
            }
        }

        return metaName || tokenName;
    }
}

export class ChiveError extends Error {
    fields: string[];

    constructor(message: string, errors: object = {}) {
        super(message);
        let errorsList: Map<number, object> = errors["errors"];
        this.fields = [];

        if (errorsList) {
            for (let error of errorsList) {
                let errorDetails = error[1];

                if (errorDetails["isValid"] === false) {
                    this.fields.push(errorDetails["field"]);
                }
            }
        }

        Object.setPrototypeOf(this, ChiveError.prototype);
    }
}

export class ChiveTokenizeError extends ChiveError {
    constructor(message: string, errors: object = {}) {
        super(message, errors);
        Object.setPrototypeOf(this, ChiveTokenizeError.prototype);
    }
}

interface Product {
    name: string;
    price: number;
    code: string;
}
