/** @format */

import $ from "jquery";

export async function check_response(response: Response): Promise<Response> {
    if (!response.ok) {
        let body: any = null;
        try {
            body = await response.json();
        } catch (err) {
            console.warn("Could not parse JSON body in error response:", err);
        }
        throw new NotOkResponseError(response, body);
    }
    return response;
}

export class NotOkResponseError extends Error {
    response: Response;
    body: any;

    constructor(response: Response, body: any) {
        super(
            `Response (${response.url}) was ${response.status} - ${response.statusText}`
        );

        this.response = response;
        this.body = body;
        this.name = "NotOkResponseError";
    }
}

export function emptyPromise() {
    // 😢
    return Promise.resolve();
}

export function get_endpoints(): { [url_name: string]: string } {
    let element = document.getElementById("js-endpoints");
    if (element) {
        return JSON.parse(element.textContent);
    } else {
        return {};
    }
}

export function addOptions(
    select: JQuery<HTMLElement>,
    options: object[]
): void {
    for (let option of options) {
        let optionElem = $("<option>", {
            value: option["id"],
        });
        optionElem.text(option["name"]);

        if (option["default"]) {
            optionElem.attr("selected", "");
        }

        select.append(optionElem);
    }
}
