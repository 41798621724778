/** @format */

import { AbstractComponent } from "../AbstractComponent";
import $ from "jquery";

enum Mode {
    Read,
    Write,
}

declare const NDEFReader: any;

export class NfcReadWriteComponent extends AbstractComponent {
    public static selector: string = "nfc-read-write-component";
    private mode: Mode = Mode.Read;
    private logElem: JQuery<HTMLElement>;
    private buttonElem: JQuery<HTMLElement>;
    private inputElem: JQuery<HTMLElement>;

    public init(): NfcReadWriteComponent {
        this.logElem = $("[data-log]");
        this.buttonElem = $("[data-change-mode]");
        this.inputElem = $("[data-nfc-input]");

        this.setupListeners();
        this.startNfcRead();

        return this;
    }

    private setupListeners(): void {
        this.buttonElem.on("click", async () => {
            if (this.mode === Mode.Read) {
                this.mode = Mode.Write;
                this.startNfcWrite();
                this.buttonElem.text("Switch to Read Mode");
            } else {
                this.mode = Mode.Read;
                this.startNfcRead();
                this.buttonElem.text("Switch to Write Mode");
            }
        });
    }

    private addLog(message: string) {
        let log = this.logElem.html();
        this.logElem.html(`${log}<br><br>${message}`);
    }

    private async startNfcRead(): Promise<void> {
        try {
            const ndef = new NDEFReader();
            await ndef.scan();
            this.addLog("> Scan started");

            ndef.addEventListener("readingerror", () => {
                this.addLog(
                    "Argh! Cannot read data from the NFC tag. Try another one?"
                );
            });

            ndef.addEventListener("reading", ({ message, serialNumber }) => {
                this.addLog(`> Serial Number: ${serialNumber}`);
                this.addLog(`> Records: (${message.records.length})`);

                for (const record of message.records) {
                    let decoded = new TextDecoder().decode(record.data);
                    this.addLog(
                        `> Record type: ${record.recordType}<br>> Data: ${decoded}`
                    );
                }
            });
        } catch (error) {
            this.addLog("Argh! " + error);
            throw error;
        }
    }

    private async startNfcWrite(): Promise<void> {
        try {
            let url = this.inputElem.val();
            const urlRecord = {
                recordType: "url",
                data: url,
            };

            const ndef = new NDEFReader();
            await ndef.write({ records: [urlRecord] });

            this.addLog("> Message written");
        } catch (error) {
            this.addLog("Argh! " + error);
            throw error;
        }
    }
}
